const UsefulLineDetail = () => {
  const data = window.history.state?.usr?.data;
  return (
    <div>
      <p className="text-body-md font-bold my-[10px] text-sat_darkblackv2 text-left block">
        {data?.name}
      </p>
      <p className="text-body-md font-bold my-[10px] text-sat_darkblackv2 text-left block">
        {data?.link}
      </p>
      <div className="text-body-sm font-normal text-sat_darkgray leading-[150%] text-left">
        <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
      </div>
    </div>
  );
};
export default UsefulLineDetail;
