import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useGetUsefulLinesQuery } from "../../app/services/usefullines";
import { NavLink } from "react-router-dom";
import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";
import { twJoin } from "tailwind-merge";
import { withNamespaces } from "react-i18next";

const UsefulLines = (props:any) => {
  const {t}=props;
  const linkTypes = [
    {
      id: 0,
      name: t('all'),
      value: "All",
    },
    {
      id: 1,
      name: t('phone'),
      value: "tel:",
    },
    {
      id: 2,
      name: t('mail'),
      value: "mailto:",
    },
    {
      id: 3,
      name: t('link'),
      value: "",
    },
    {
      id: 4,
      name: t('whatsapp'),
      value: "https://wa.me/",
    },
    {
      id: 5,
      name: t('text'),
      value: "Text",
    },
  ];
  const [usefulLinesData, setUsefulLinesData] = useState<any>([]);
  const [type, setType] = useState<number>(0);
  const { data: usefulLines, isLoading } = useGetUsefulLinesQuery();
  useEffect(() => {
    if (usefulLines) {
      setUsefulLinesData(usefulLines?.usefulLinkList);
    }
  }, [usefulLines]);
  useEffect(()=>{
    const data:any=usefulLines?.usefulLinkList;
    if(type!=0){
      setUsefulLinesData([...data?.filter((x:any)=>x.id==type)])
    }else{
      setUsefulLinesData(data);
    }
  },[type])
  return (
    <div className="">
      <div className="flex">
        <div className={twJoin(" items-center mr-[10px] w-auto my-1 px-6")}>
          <CustomDropdownListForFilter
            t={t}
            placeholder={'link_type'}
            state={type}
            setState={setType}
            dropdownItems={
              linkTypes
                ? [
                    ...linkTypes.map((st: any, key) => ({
                      value: st.id,
                      label: st.name,
                    })),
                  ]
                : []
            }
            textAlign="text-left"
            classNameCus="max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
          />
        </div>
      </div>
      {isLoading == true ? (
        <CircularProgress />
      ) : (
        <ul>
          {usefulLinesData?.map((link: any, key: number) => {
            const linkdata =
              link.type == "text"
                ? "/profile/useful_lines/" + link.id
                : link.link;
            return (
              <li className="p-5 w-full text-left border-b border-b-sat_borderv2">
                {link.type == "Text" ? (
                  <NavLink
                    state={{
                      data: {
                        ...link,
                      },
                    }}
                    to={{ pathname: "" + link.id }}
                    className="text-sat_link underline underline-offset-2 text-body-md w-full"
                  >
                    {link.name}
                  </NavLink>
                ) : (
                  <a
                    target="_blank"
                    href={linkdata}
                    className="text-sat_link underline underline-offset-2 text-body-md w-full"
                  >
                    {link.name}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(UsefulLines);
