// src/components/BlurOverlay.js
import React from 'react';

const BlurOverlay = ({ isVisible }:any) => {
    return (
        <div className={`blur-overlay ${isVisible ? 'active' : ''}`}>
            {/* You can add any content here, such as a loading spinner */}
        </div>
    );
};

export default BlurOverlay;
