import { NavLink } from "react-router-dom";
import {
  AssignDataIcon,
  NewAssignIcon,
  SubmissionIcon,
  UpcomingIcon,
} from "../common/Icons";
import { withNamespaces } from "react-i18next";
import { twJoin } from "tailwind-merge";
export const typeList = [
  {
    icon: <SubmissionIcon />,
    name: "Submission",
  },
  {
    icon: <UpcomingIcon />,
    name: "Upcoming",
  },
  {
    icon: <AssignDataIcon />,
    name: "Assigned",
  },
  {
    icon: <NewAssignIcon />,
    name: "New",
  },
];
export const change_time_format=(time:string)=>{
  const timestr=time.split(' ');
}
const NotificationDataList = (props: any) => {
  const { t } = props;
  return (
    <div>
      <ul>
        {props.notifications?.news?.length > 0 ? (
          <p className="px-5 text-body-sm font-normal text-sat_darkgray400 mb-1 text-left">
            {t("news")}
          </p>
        ) : (
          ""
        )}
        {props.notifications?.news?.map((noti: any, key: number) => {
          const icon = typeList.find((x) => x.name == noti.type)?.icon;         
          return (
            <li
              className="mb-6"
              onClick={() => props.readNotificationData(noti.id)}
            >
              <NavLink
                state={{
                  data: {
                    ...noti,
                  },
                }}
                to={{ pathname: "/notifications/" + noti.id }}
              >
                <div className={twJoin(noti?.is_read==true?'':'bg-[#F2F5FA] ',"px-5 py-3 flex border-t border-t-sat_borderv2")}>
                  <div className="min-w-5 min-h-5">
                    {icon ? icon : <NewAssignIcon />}
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex items-center justify-between text-left">
                      <p className="text-body-md text-sat_darkblackv2 leading-[150%]">
                        {noti?.title}
                      </p>
                      {noti?.is_read == false ? (
                        <p className="w-[6px] h-[6px] rounded-full bg-sat_primary mr-[9px]"></p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex items-center justify-between text-left">
                      <div
                        className="text-body-sm text-sat_darkgray leading-[150%] line-clamp-1"
                        dangerouslySetInnerHTML={{ __html: noti?.content }}
                      ></div>
                      <p className="text-[9px] text-sat_grayHint min-w-[45px]">
                        {noti?.human_time}
                      </p>
                    </div>
                  </div>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
      <ul>
        {props.notifications?.yesterdayList?.length > 0 ? (
          <p className="px-5 text-body-sm font-normal text-sat_darkgray400 mb-1 text-left">
            {t("yesterday")}
          </p>
        ) : (
          ""
        )}
        {props.notifications?.yesterdayList?.map((noti: any, key: number) => {
          const icon = typeList.find((x) => x.name == noti.type)?.icon;
          return (
            <li
              className="mb-6"
              onClick={() => props.readNotificationData(noti.id)}
            >
              <NavLink
                state={{
                  data: {
                    ...noti,
                  },
                }}
                to={{ pathname: "/notifications/" + noti.id }}
              >
                <div className={twJoin(noti?.is_read==true?'':'bg-[#F2F5FA] ',"px-5 py-3 flex border-t border-t-sat_borderv2")}>
                  <div className="min-w-5 min-h-5">
                    {icon ? icon : <NewAssignIcon />}
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex items-center justify-between text-left">
                      <p className="text-body-md text-sat_darkblackv2 leading-[150%]">
                        {noti?.title}
                      </p>
                      {noti?.is_read == false ? (
                        <p className="w-[6px] h-[6px] rounded-full bg-sat_primary mr-[9px]"></p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex items-center justify-between text-left w-full">
                      <div
                        className="text-body-sm text-sat_darkgray leading-[150%] line-clamp-1"
                        dangerouslySetInnerHTML={{ __html: noti?.content }}
                      ></div>
                      <p className="text-[9px] text-sat_grayHint min-w-[45px]">
                        {noti?.human_time}
                      </p>
                    </div>
                  </div>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
      <ul>
        {props.notifications?.announcementList?.map(
          (noti: any, key: number) => {
            const icon = typeList.find((x) => x.name == noti.type)?.icon;
            return (
              <li
                className="mb-6"
               
              >
                <div>
                  <p className="px-5 text-body-sm font-normal text-sat_darkgray400 mb-1 text-left">
                    {noti.date}
                  </p>
                  <ul>
                    {noti?.data?.map((notiD: any, key: number) => {
                      return (
                        <li  onClick={() => props.readNotificationData(notiD.id)} className="border-t border-t-sat_borderv2">
                          <NavLink className={twJoin(notiD?.is_read==true?'':'bg-[#F2F5FA] ',"px-5 py-3 flex ")}
                            state={{
                              data: {
                                ...notiD,
                              },
                            }}
                            to={{ pathname: "/notifications/" + notiD.id }}
                          >
                            <div className="min-w-5 min-h-5">
                              {icon ? icon : <NewAssignIcon />}
                            </div>
                            <div className="ml-3 w-full">
                              <div className="flex items-center justify-between text-left">
                                <p className="text-body-md text-sat_darkblackv2 leading-[150%]">
                                  {notiD?.title}
                                </p>
                                {notiD?.is_read == false ? (
                                  <p className="w-[6px] h-[6px] rounded-full bg-sat_primary mr-[9px]"></p>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="flex items-center justify-between text-left">
                                <div
                                  className="text-body-sm text-sat_darkgray leading-[150%] line-clamp-1"
                                  dangerouslySetInnerHTML={{
                                    __html: notiD?.content,
                                  }}
                                ></div>
                                <p className="text-[9px] text-sat_grayHint min-w-[45px]">
                                  {notiD?.human_time}
                                </p>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(
  NotificationDataList
);
