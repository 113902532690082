import { NavLink, useNavigate } from "react-router-dom";
import logo from "../img/sat-logo.svg";
import menu from "../img/solar_qr-code-line-duotone.svg";
import { useEffect, useState } from "react";
import QRForm from "../../components/QRForm/QRForm";
import { withNamespaces } from "react-i18next";
import { change_axios_language } from "../../app/services/api";
const Sat_Landing = (props: any) => {
  const { logoData } = props;
  const {t}=props;
  useEffect(() => {
    change_axios_language();
    // setTimeout(() => {
    //   setQrShow(true);
    // }, 3000);
  }, []);
  return (
    <>
      <div className="max-w-full h-[92vh] overflow-hidden">
        <div className="max-w-[640px] mx-auto h-full flex flex-col justify-center pb-[200px] relative">
          <div className="">
            <img
              src={logoData?.logo ? logoData?.logo : logo}
              alt="logo"
              className="object-contain mx-auto relative w-full max-w-[256px]"
            />
            <div className="mt-7">
              <h1 className="text-body-23 font-secondary max-w-[256px] mx-auto">
                {t("landing_description")}
              </h1>
            </div>
          </div>
          <div className="mt-7 absolute bottom-[60px] left-1/2 -translate-x-1/2">
            <NavLink to={{ pathname: "/profile/qr" }}>
              <div className="inline-block rounded-full p-4 shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]">
                <img
                  src={menu}
                  alt="menu"
                  className="max-w-[48px] object-contain mx-auto relative"
                />
              </div>
              <p className="heading2 font-secondary mt-5 text-center text-sat_darkblackv2">
              {t("landing_qr")}
              </p>
            </NavLink>
          </div>
        </div>
      </div>
      {/* <QRForm showQr={showQr} setQrShow={setQrShow}/> */}
    </>
  );
};
export default withNamespaces((props) => props.namespaces)(Sat_Landing);
