import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { withErrorBoundary } from "./layouts/RoutedErrorBoundary";
import LoginRoute from "./login";
import ForgetPasswordRoute from "./forgetpassword";
import EmailSentRoute from "./emailsent";
import SectionRoute from "./auth/components/SectionRoute";
import UsersRoute, { empRoutes, userRoutes } from "./userProfile";
import DashboardRoute from "./dashboard";
import { Dashboard } from "./dashboard/views/Dashboard";
import MedaiRoute from "./media";
import PermissionRoute from "./permissions";
import BackendUserRoute, { backendUserRoutes } from "./backendUsers";
import NotFoundPage from "./components/NotFoundPage";
import ProjectsRoute, { projectRoutes } from "./projects";
import jwt_decode from "jwt-decode";
import { passwordRoutes } from "./passwords";
import ActiveTimeReportRoute from "./active-time-report";
import { employeeRoutes } from "./employee-list";
import AnnouncementRoute, { announcementRoutes } from "./announcements";
import Documents, { documentRoutes } from "./documents";
// import LeaveApplication from "./components/DashboardProfile/MyLeave/LeaveApplication";
// import LeaveApplicationRoute from "./leave-application";
import { logout } from "./app/services/auth.service";
import ResetPasswordRoute from "./reset-password";
import PasswordResetSuccessRoute from "./password-reset-success";
import Passwords from "./passwords";
import CrmRoute, { crmRoutes } from "./crm";
import PerformanceAppraisalRoute, {
  performanceRoutes,
} from "./performance-appraisal";
import PerformanceAppraisalDetailRoute from "./performance-appraisal-detail";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import ActivityLogRoute from "./activity-log";
// import ProjectBoardRoute from "./pro";
import NotificationView from "./components/NotificationView/NotificationView";
import NotificationRoute from "./notificationlist";
import { lazy, Suspense, useState } from "react";
import { useGetLoginDataMutation } from "./app/services/dashboard";
import { useEffect } from "react";
// import CommonTableSkeleton from "./components/TableSkeleton/CommonTableSkeleton";
import EmployeeIncentiveRoute from "./employee-incentive";
import ExchangeRateRoute from "./exchange-rate";
import DocumentViews from "./components/DocumentView/DocumentView";
import ProjectCheckerRoute from "./project-checker";
import ProjectCheckerAccRoute from "./projectCheckerAcc";
import TaskListRoute from "./tasks-list";
import TaskDetailListRoute from "./task-detail-list";
import TaskCategoryList from "./taskCategory/view/TaskCategory";
import BasicLayout from "./layouts/BasicLayout";
import ProjectStatus from "./projectStatus/view/ProjectStatus";
import ProjectTech from "./project-tech/view/ProjectTech";
import AnnoucementListRoute from "./annoucementlist";
import EmployeeLeaveRoute from "./employeeLeave";
import MonitoringDetailRoute, {
  monitoringDetailRoute,
} from "./monitoring-detail";
// import Courses from "./lms/courses/view/Courses";
import CRMDetailRoute, { crmDetailRoute } from "./crmDetail";
import Department from "./department/view/Department";
import Designation from "./designation/view/Designation";
import WorkingShiftRoute from "./working-shift";
import CoursesRoute from "./lms/courses/index";
import Courses from "./lms/courses/index";
import { courseSection, instructorSection } from "./lms/urls";
import {
  DashBoardPermis,
  MediaPermis,
  ActivityPermis,
  UserPemis,
  BKUserPermis,
  ProjectPermis,
  ProjectCheckerPermis,
  ProjectCheckerAccPermis,
  TaskListPermis,
  PasswordPermis,
  EmployeePermis,
  AnnouncPermissions,
  DocumentPemis,
  CrmPermis,
  claimTypePermissions,
  claimFormPermissions,
  incentivePermissions,
  exchangeRatePermissions,
  offDayPermissions,
  TaskCategoryPermis,
} from "./types/permissions";
import InstructorRoute from "./lms/instructor";
import Sat_Landing from "./sat-landing/components/Sat_Landing";
import QRForm from "./components/QRForm/QRForm";
import UserProfile from "./components/UserProfile/UserProfile";
import UserInfo from "./components/UserInfo/UserInfo";
import Setting from "./setting/components/Setting";
import Language from "./setting/components/Language";
import { withNamespaces } from "react-i18next";
import CustomPdfViewer from "./components/CustomPdfViewer/CustomPdfViewer";
import NoticePopupV2 from "./components/NoticePopup/NoticePopupV2";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Manual from "./components/Manual/Manual";
import CalendarEvent from "./components/CalendarEvent/CalendarEvent";
import Logout from "./components/logout/Logout";
import NotificationList from "./components/NotificationList/NotificationList";
import NotificationDetail from "./components/NotificationList/NotificationDetail";
import NoticeBoard from "./components/UserInfo/NoticeBoard";
import BasicInfoSection from "./components/UserInfo/BasicInfoSection";
import FaqList from "./components/FaqList/FaqList";
import UsefulLines from "./components/UsefulLines/UsefulLines";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./components/PrivacyPolicy/TermsCondition";
import { useGetLogoDataQuery } from "./app/services/cms";
import UsefulLineDetail from "./components/UsefulLines/UsefulLineDetail";
import QRFormPage from "./components/QRForm/QRFormPage";

// import TaskDetailList from "./task-detail-list/view/TaskDetailList";
// import { useJwt } from "react-jwt";

const LeaveTypeRoute = lazy(() => import("./leave-type"));
const ProjectBoardRoute = lazy(() => import("./project-board"));
const EmployeeListRoute = lazy(() => import("./employee-list"));
const ProjectKeyDateRoute = lazy(() => import("./project-keydate"));
// const PayRollRoute = lazy(() => import("./payroll"));
const ClaimFormRoute = lazy(() => import("./claim-form"));
// const PayrollRateRoute = lazy(() => import("./payroll-rate"));
const ClaimType = lazy(() => import("./claim-type"));
const OffDayRoute = lazy(() => import("./off-day-office-working"));
const AddClaimTypeRoute = lazy(() => import("./claim-new"));

export interface userData {
  email: string;
  token: string;
  role: string;
}

export const checkToken = (route: any) => {
  var userData = localStorage.getItem("userData");
  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  var isValid = false;
  if (userJson.token != undefined && userJson.token != null) {
    try {
      const decodedToken: { sub: string; role: string; exp: number } =
        jwt_decode(userJson.token);
      const userId = decodedToken.sub;
      const userRole = decodedToken.role;
      if (decodedToken.exp * 1000 < Date.now()) {
        logout();
        route("/login");
      }
      isValid = true;
    } catch (e) {
      isValid = false;
    }
  }
  return isValid;
};

const MainRouter = (props: any) => {
  const { t } = props;
  const [logoData, setLogoData] = useState<any>();
  const { data: logos, isLoading: logoLoading } = useGetLogoDataQuery();
  useEffect(() => {
    if (logos) {
      setLogoData(logos);
    }
  }, [logos]);
  const [getLoginData] = useGetLoginDataMutation();
  var pathData = new URLSearchParams(window.location.search);
  var code = pathData.get("code");
  var state = pathData.get("state");
  var userData = localStorage.getItem("userData");
  useEffect(() => {
    if (code && state) {
      var data = {
        code: code,
        state: state,
      };
      getLoginData(data).then((res: any) => {
        if (res.data) {
          userData = localStorage.getItem("userData");
        }
      });
    }
  }, []);
  const navigate = useNavigate();

  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  var data = userJson;

  var isValid = checkToken(navigate);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <LoginRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/forgetpassword"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <ForgetPasswordRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <ResetPasswordRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/profile/logout"
          element={
            <UserProfile isBack={true} userData={data} title={t('logout')} {...props}>
              <Logout />
            </UserProfile>
          }
        />
        <Route
          path="/resetpassword-success"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <PasswordResetSuccessRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/emailsent"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <EmailSentRoute />
            </SectionRoute>
          }
        />
        <Route path="/profile/qr" element={<QRFormPage logoData={logoData}/>} />
        <Route path="/landing" element={<Sat_Landing logoData={logoData}/>} />
        <Route
          path="/setting"
          element={
            <UserProfile userData={data} title={t('setting')} {...props}>
              <Setting loginData={data} />
            </UserProfile>
          }
        />
        <Route
          path="/pdfview"
          element={
            <UserProfile isBack={true} userData={data} title={t('view')} {...props}>
              <CustomPdfViewer />
            </UserProfile>
          }
        />
        <Route
          path="/profile/calendar"
          element={
            <UserProfile userData={data} title={t('calendar')} {...props}>
              <CalendarEvent />
            </UserProfile>
          }
        />
        <Route
          path="/notifications"
          element={
            <UserProfile userData={data} title={t('notification')} {...props}>
              <NotificationList />
            </UserProfile>
          }
        />
        <Route
          path="/profile/language"
          element={
            <UserProfile isBack={true} userData={data} title={t("language")} {...props}>
              <Language />
            </UserProfile>
          }
        />
        <Route
          path="/profile/setting"
          element={
            <UserProfile userData={data} title={t("setting")} {...props}>
              <Setting />
            </UserProfile>
          }
        />
         <Route
          path="/profile/change_password"
          element={
            <UserProfile isBack={true} userData={data} title={t("change_password")} {...props}>
              <ChangePassword />
            </UserProfile>
          }
        />
         <Route
          path="/profile/manuals"
          element={
            <UserProfile userData={data} title={t("manual")} {...props}>
              <Manual />
            </UserProfile>
          }
        />
         <Route
          path="/profile/useful_lines"
          element={
            <UserProfile isBack={true} userData={data} title={t("usefulline")} {...props}>
              <UsefulLines />
            </UserProfile>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <UserProfile isBack={true} userData={data} title={t("privacy")} {...props}>
              <PrivacyPolicy />
            </UserProfile>
          }
        />
        <Route
          path="/terms-condition"
          element={
            <UserProfile isBack={true} userData={data} title={t("terms")} {...props}>
              <TermsCondition />
            </UserProfile>
          }
        />
        <Route
          path="/profile/notice_board/:id"
          element={
            <UserProfile isBack={true} userData={data} title={t("notice")} {...props}>
              <NoticePopupV2 />
            </UserProfile>
          }
        />
        <Route
          path="/profile/useful_lines/:id"
          element={
            <UserProfile isBack={true} userData={data} title={t('usefulline')} {...props}>
              <UsefulLineDetail />
            </UserProfile>
          }
        />
        <Route
          path="/profile"
          element={
            <UserProfile userData={data} title={t("profile")} {...props}>
              <BasicInfoSection />
            </UserProfile>
          }
        />
        <Route
          path="/faq"
          element={
            <UserProfile isBack={true} userData={data} title={t("faq")} {...props}>
              <FaqList />
            </UserProfile>
          }
        />
        <Route
          path="/profile/notice_board"
          element={
            <UserProfile userData={data} title={t("profile")} {...props}>
              <NoticeBoard />
            </UserProfile>
          }
        />
        <Route
          path="/profile/notice_board1"
          element={
            <UserProfile userData={data} title={t("profile")} {...props}>
              <NoticeBoard />
            </UserProfile>
          }
        />
         <Route
          path="/notifications/:id"
          element={
            <UserProfile isBack={true} userData={data} title={t("notification")} {...props}>
              <NotificationDetail />
            </UserProfile>
          }
        />
        <Route
          path="/employeeprofile/:id"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <UsersRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {userRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/users"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={BKUserPermis}
            >
              <BackendUserRoute loginData={data} permissions={BKUserPermis} />
            </SectionRoute>
          }
        >
          {backendUserRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="*"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <PageNotFound />
            </SectionRoute>
          }
        ></Route>
      </Routes>
    </>
  );
};

export default withErrorBoundary(
  withNamespaces((props) => props.namespaces)(MainRouter)
);
