import { NavLink } from "react-router-dom";
import {
  AssignDataIcon,
  NewAssignIcon,
  SubmissionIcon,
  UpcomingIcon,
} from "../common/Icons";
import { withNamespaces } from "react-i18next";
import { useGetFaqListQuery } from "../../app/services/faq";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
const FaqList = (props: any) => {
  const { t } = props;
  const [faqsData, setFaqsData] = useState<any>([]);
  const { data: faqs, isLoading } = useGetFaqListQuery();
  useEffect(() => {
    setFaqsData(faqs?.faqData);
  }, [faqs]);
  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="px-5">
          <ul>
            {faqsData?.map((faq: any, key: number) => {
              return <li key={key} className="mb-7 pb-7 border-b border-b-sat_borderv2">
                <h2 className="heading2 text-sat_darkblackv2 font-bold text-left">{faq.name}</h2>
                <ul className="pl-5">
                  {faq?.faqs.map((item:any,index:number)=>{
                    return <li key={index} className="mb-4 last:mb-0 list-decimal font-bold text-body-md first:pt-5">
                      <h3 className="font-bold text-sat_darkblackv2 text-body-md mb-2 text-left">{item.title}</h3>
                      <p className="font-normal text-sat_darkblackv2 text-body-md text-sat_darkgray text-left" dangerouslySetInnerHTML={{__html:item.description}}></p>
                    </li>
                  })}
                </ul>
              </li>
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(FaqList);
