import { Capacitor } from "@capacitor/core";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { useLocation } from "react-router-dom";
import { withNamespaces } from "react-i18next";
// import { Plugins } from '@capacitor/core';
// const { FileOpener } = Plugins;
const CustomPdfViewer = (props:any) => {
  const {t}=props;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const userStr = localStorage.getItem("userData");
  let user = null;
  if (userStr) user = JSON.parse(userStr);
  let location = useLocation();
  const onButtonClick = (file: string) => {
    // using Java Script method to get PDF file
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        let filename = file.includes("hand_book/")
          ? file?.split("hand_book/")
          : file?.split("notice_boards/");
        alink.download = filename ? filename[filename.length - 1] : file;
        alink.click();
      });
    });
    
  };
  async function downloadAndOpenPDF(pdfUrl: string) {
    try {
      // Step 1: Fetch the PDF from the URL
      const response = await fetch(pdfUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      const blob = await response.blob();
  
      // Step 2: Convert the Blob to Base64
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = reader.result as string;
  
        // Step 3: Save the PDF to the filesystem
        let filename = pdfUrl.includes("hand_book/")
        ? pdfUrl?.split("hand_book/")
        : pdfUrl?.split("notice_boards/");
        console.log("filename ",filename)
        await Filesystem.writeFile({
          path: filename ? filename[filename.length - 1]:'myfile.pdf',
          data: base64Data.split(',')[1], // Only take the Base64 string part
          directory: Directory.Documents // Save in the Documents directory
        });
        console.log("Directory.Documents ",Directory.Documents)
        
        // Step 4: Get the URI of the saved file
        const fileUri = await Filesystem.getUri({
          path: filename ? filename[filename.length - 1]:'myfile.pdf',
          directory: Directory.Documents
        });
  
        console.log('File saved at:', fileUri.uri);
       // const localFilePath = fileUri.uri.replace('file://', 'capacitor://localhost/_capacitor_file_/');
        // Step 5: Open the PDF using FileOpener
        await FileOpener.open({
          filePath: fileUri.uri,
          openWithDefault: true // Specify the mime type
        });
      };
  
      // Read the Blob as a Data URL
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error downloading or opening PDF:', error);
    }
  }
  async function checkFile(pdfUrl: string) {
    try {
      let filename = pdfUrl.includes("hand_book/")
        ? pdfUrl?.split("hand_book/")
        : pdfUrl?.split("notice_boards/");
        const fileUri = await Filesystem.getUri({
            path: filename ? filename[filename.length - 1]:'myfile.pdf',
            directory: Directory.Documents
        });

        console.log('Checking file at:', fileUri.uri);

        const fileCheck = await Filesystem.readFile({
            path: filename ? filename[filename.length - 1]:'myfile.pdf',
            directory: Directory.Documents
        });

        console.log('File exists:', fileCheck);
    } catch (error) {
        console.error('File does not exist:', error);
    }
}
  return (
    <div className="">
      <div className="flex justify-center px-5">
        {location.state?.is_download==true?
        <button
          className="bg-sat_primary text-white text-body-sm font-bold font-secondary px-3 py-2 rounded-lg w-auto inline-block ml-auto mb-5"
          onClick={() => {downloadAndOpenPDF(location.state?.filepath);
          checkFile(location.state?.filepath)}}
        >
          {t('download')}
        </button>:null}
      </div>
      <div className="w-full ">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          plugins={
            [
              // Register plugins
             // defaultLayoutPluginInstance,
            ]
          }
          defaultScale={0.65}
          fileUrl={location.state?.filepath}
        />
      </Worker>
      </div>     
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(CustomPdfViewer);
