import { FC, useEffect, useState } from "react";
import { renderDateFormat, renderDateFormatDMY } from "../DashboardCalendarView/DashboardAddEventPopup";
import { twJoin } from "tailwind-merge";

interface ICalendarDetail{
  eventData:any;
    selectedDate: string
}
const CalendarEventDetail:FC<ICalendarDetail>=({
  eventData,
    selectedDate
})=>{
  const [eventsDataList, setEventsDataList] = useState<any>([]);
  const date_click=(date:string)=>{    
    var currentDate = renderDateFormat(new Date(selectedDate), "-");
    const evt_data = eventData ? [...eventData] : [];
    var filterData: any = evt_data?.filter((item: any, key: number) => {
      const startDate = new Date(item.start);
      const endDate = new Date(item.end);
      const curDate = new Date(new Date(date));
      if (item.date == currentDate) {
        return item;
      } else if (startDate <= curDate && curDate <= endDate) {
        return item;
      }
    });
    setEventsDataList(filterData);
  }
  // useEffect(()=>{
  //   date_click(selectedDate)
  // },[selectedDate])
  document.querySelectorAll('td').forEach(function(td){
    td.addEventListener('click',function(){
      const date=td.getAttribute('data-date');
      if(date!=null){
        date_click(date??'')
      }
      
    })
  })
    return   <div className="event-list mt-4 md:max-h-[40vh] max-h-[28vh] pr-2 overflow-auto custom-scrollbar">
    <ul>
      {eventsDataList?.map((item: any, key: number) => {
        return (
          <li
            key={key}
            className="pb-4 mb-4 border-b border-b-sat_borderv2 text-left"
          >
            <div className="flex justify-between items-center">
              <div className="mr-5">
                <p className="text-body-sm text-sat_darkgray400 font-normal ">
                  {item.start
                    ? renderDateFormatDMY(new Date(item.start), "-")
                    : ""}{" "}
                  {item.end
                    ? renderDateFormatDMY(new Date(item.end), "-")
                    : ""}
                </p>
                <p className="heading3 text-sat_darkblackv2 font-normal ">
                  {item.title}
                </p>
              </div>
              <div>
                <p
                  className={twJoin(
                    `bg-[${item.eventType?.color}] bg-opacity-30`,
                    "text-text-body-sm font-normal rounded-[13px] py-1 px-2 bg-opacity-30 text-body-sm"
                  )}
                  style={{
                    color: item.eventType?.color,
                    backgroundColor: item.eventType?.color + "1A",
                  }}
                >
                  {item.eventType?.typeName}
                </p>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  </div>
}
export default CalendarEventDetail;