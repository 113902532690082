import { useContext, useEffect, useState } from "react";
import CustomOTPTimer from "../../components/common/CustomOTPTimer";
import back from "../../forgetpassword/img/back-page.svg";
import password_logo from "../../forgetpassword/img/password-logo.svg";
import { twJoin } from "tailwind-merge";
import axios from "../../axios";
import { change_axios_language, endpointUrl } from "../../app/services/api";
import { withNamespaces } from "react-i18next";
const EmailSentComponent = (props:any) => {
  const {logoData,t}=props;  
  const email = localStorage.getItem("email");
  const [otpExpired, setOTPExpired] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const otpTimeout = () => {
    setResetTimer(false);
    if (resetTimer == false) {
      setOTPExpired(true);
    }
  };
  const resend = () => {
    axios
      .post(endpointUrl + "password/forgot", {
        email: email,
      })
      .then(function (response) {
        setOTPExpired(false);
        setResetTimer(true);
      })
      .catch((reason) => {
        setOTPExpired(false);
        setResetTimer(true);
      });
  };
  // const resend = () => {
  //   setOTPExpired(false);
  //   setResetTimer(true);
  // };
  useEffect(()=>{
    change_axios_language();
  },[]);
  return (
    <div className="max-w-full">
      <div className="w-full mx-auto px-5">
        <div className="py-5 relative left-0 w-full top-0 bg-white">
          <button
            onClick={() => window.history.back()}
            className="absolute left-0 top-1/2 -translate-y-1/2"
          >
            <img src={back} alt="back" className="" />
          </button>
          {/* <h3 className="heading3 font-secondary text-center text-sat_darkblackv2 w-full">
            Forgot Password
          </h3> */}
        </div>
        <div className="pt-16 pb-10 h-[90vh] flex flex-col justify-between">
          <div>
            <img
              src={password_logo}
              alt="password_logo"
              className="mx-auto max-w-full"
            />
            <div className="mt-10">
              <p className="heading1 font-secondary font-normal text-sat_darkgray leading-[150%] text-center">
                {t('email_title1')}
              </p>
              <p className="mt-3 text-body-16 font-normal text-sat_darkgray text-center">
              {t('email_title2')}
              </p>

              <div className="mt-5 text-center">
                {/* <div className="heading3 font-normal font-secondary text-sat_darkblackv2 flex items-center justify-center">
                  Confirm within
                  <CustomOTPTimer
                    duration={60}
                    onTimeout={otpTimeout}
                    resetTimer={resetTimer}
                    resend={resend}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="mt-10">
            <p className="font-secondary font-normal text-body-sm text-sat_darkblackv2">
             {t('received_email')}
              <button
                onClick={()=>resend()}
                className={twJoin(
                 // otpExpired == false ? "opacity-50 pointer-events-none" : "",
                  "text-body-sm font-secondary font-normal text-sat_primary ml-1"
                )}
              >
                {t('resend')}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(EmailSentComponent);
