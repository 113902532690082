import { Modal } from "@mui/material";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import download_icon from "./img/pdf_download.svg";
import pdf_icon from "./img/pdf-icon.svg";
import { twJoin } from "tailwind-merge";
import { useReadNoticeBoardDetailMutation } from "../../app/services/notice-board";
import { withNamespaces } from "react-i18next";
interface INoticePopup {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data:any;
  props:any;
}
const annoucement_tags = [
  {
    id: 1,
    name: "Company",
  },
  {
    id: 2,
    name: "Announcement",
  },
  {
    id: 3,
    name: "Employee",
  },
];
const NoticePopup = (props:any) => {
  const {isOpen,setIsOpen,data,t}=props;
  const [readNoticeBoard]=useReadNoticeBoardDetailMutation();
  const [checkAcknowledge, setCheckAcknowledge] = useState<boolean>(false);
  const filename=data?.file_url?.split('/');
  const readNoticeBoardDetail=(id:number)=>{
    readNoticeBoard(id).then((res:any)=>{
      console.log('read ',res)
    })
  }
  return (
    <Modal
      className="notice_popup"
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modalContent rounded-xl annoucement-modal overflow-y-auto max-h-[90vh] max-w-[95%] w-full focus-visible:outline-none">
        <div className="bg-white max-w-[360px] mx-auto rounded-2xl overflow-hidden">
          <div className="bg-sat_orange p-4 rounded-tl-2xl rounded-tr-2xl">
            <h1 className="heading2 font-bold text-white text-left">
              {data?.name}
            </h1>
            <p className="text-body-sm font-normal my-[10px] text-white">
              {data?.published_at}
            </p>
            <div>
              <ul className="flex flex-wrap items-center">
                {data?.categories?.map((annou: any, key: number) => {
                  return (
                    <li
                      className="mr-1 last:mr-0 my-1 px-2 py-1 text-body-sm rounded-[13px] text-white border border-sat_whitepale"
                      key={key}
                    >
                      {annou.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="bg-white p-4 text-body-md text-sat_darkgray font-secondary">
            <div className="max-h-[300px] overflow-auto">
              <p className="text-body-sm text-sat_darkgray" dangerouslySetInnerHTML={{__html:data?.description}}></p>
              
              <div className="mt-3 p-2 rounded-lg bg-sat_lightgray flex items-center justify-between">
                <div className="mr-2 flex items-center">
                  <div className="pr-3">
                    <img src={pdf_icon} alt="pdf_icon" />
                  </div>
                  <div>
                    <p className="font-normal text-body-sm text-sat_darkblackv2 truncate max-w-[140px]">
                      {filename?filename[filename.length-1]:''}
                    </p>
                    <p className="font-normal texd-body-sm text-sat_smoke_light">
                      {data?.file_size}
                    </p>
                  </div>
                </div>
                <a
                  href={data?.file_url}
                  download={true}
                  className="rounded-lg bg-sat_primary p-2"
                >
                  <img src={download_icon} alt="download_icon" />
                </a>
              </div>
            </div>

            <div className="mt-4">
              <label
                htmlFor="acknowledge"
                className="relative flex items-center"
              >
                <div className="relative flex">
                  <input
                    onChange={(e)=>{
                        setCheckAcknowledge(e.target.checked)
                    }}
                    type="checkbox"
                    id="acknowledge"
                    className="mr-2 w-5 h-5 bg-sat_smoke rounded-4 checked:before:bg-sat_primary checked:after:w-3 checked:after:h-[0.45rem] checked:after:border-l-2 checked:after:border-l-white checked:after:border-b-2 checked:after:border-b-white checked:after:content-normal checked:before:content-normal checked:before:absolute checked:after:absolute checked:after:rotate-[-45deg] checked:before:left-0 checked:before:top-0 checked:before:rounded-4 checked:before:w-5 checked:before:h-5 checked:after:left-[35%] checked:after:top-[40%] checked:after:-translate-x-1/2 checked:after:-translate-y-1/2"
                  />
                </div>
                <span>{t('read_ack')}</span>
              </label>
            </div>
            <div className="mt-4">
              <button onClick={()=>{
                setIsOpen(false);
                readNoticeBoardDetail(data?.id)
                 window.localStorage.setItem('cookie',"true");
              }}
                className={twJoin(
                    checkAcknowledge==true
                    ? "opacity-100"
                    : "opacity-50 pointer-events-none",
                  "w-full py-3 px-5 bg-sat_primary text-white text-body-md text-center rounded-lg"
                )}
              >
                {t('ok')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default withNamespaces((props) => props.namespaces)(NoticePopup);
