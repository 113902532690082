import { Modal } from "@mui/material";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import download_icon from "./img/pdf_download.svg";
import pdf_icon from "./img/pdf-icon.svg";
import { twJoin } from "tailwind-merge";
import UserProfile from "../UserProfile/UserProfile";
import { NavLink } from "react-router-dom";
import {
  useGetNoticeBoardDataByIDQuery,
  useReadNoticeBoardDetailMutation,
  useReadNoticeBoardQuery,
} from "../../app/services/notice-board";
import { withNamespaces } from "react-i18next";
interface INoticePopup {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
const NoticePopupV2 = (props:any) => {
  const{t}=props;
  const [isRead, setIsRead] = useState<boolean>(false);
  const [readNoticeBoard] = useReadNoticeBoardDetailMutation();
  const data = window.history.state?.usr?.data;
  const [open, setIsOpen] = useState<boolean>(false);
  const readNoticeBoardDetail = (id: number) => {
    readNoticeBoard(id).then((res: any) => {
      setIsRead(true);
    });
  };
  const fileurl = data?.url?.split("/");
  const filename = fileurl?.length > 0 ? fileurl[fileurl.length - 1] : "";
  return (
    <div className="px-5">
      <div className="">
        <p className="text-body-sm font-normal my-[10px] text-sat_darkgray400 text-left">
          {data?.date}
        </p>
        <h1 className="heading2 font-bold text-sat_darkblackv2 text-left">
          {data?.title}
        </h1>
        <div>
          <ul className="flex items-center flex-wrap">
            {data?.tags?.map((tag: any, key: number) => {
              return (
                <li
                  key={key}
                  style={{ backgroundColor: tag.color ? tag.color : "#6695C1" }}
                  className={twJoin(
                    "inline-block mr-1 my-1 last:mr-0 text-text-body-sm text-white font-secondary px-2 py-1 rounded-[13px]"
                  )}
                >
                  {tag.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="bg-white pt-5 mt-5 text-body-md text-sat_darkgray font-secondary border-t border-t-sat_borderv2 text-left">
        <div className="h-[46vh] flex flex-col justify-between">
          <div className="max-h-[40vh] overflow-auto">
            <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
            {filename?
            <NavLink
              to={{ pathname: "/pdfview" }}
              state={{ is_download: data.is_download, filepath: data.url }}
              className="mt-3 p-2 rounded-lg bg-sat_lightgray flex items-center justify-between"
            >
              <div className="mr-2 flex items-center">
                <div className="pr-3">
                  <img src={pdf_icon} alt="pdf_icon" />
                </div>
                <div>
                  <p className="font-normal text-body-sm text-sat_darkblackv2 truncate max-w-[140px]">
                    {filename}
                  </p>
                  <p className="font-normal texd-body-sm text-sat_smoke_light">
                    {data?.filesize}
                  </p>
                </div>
              </div>
              {data?.is_download==true?
              <a
                onClick={(e) => e.stopPropagation()}
                href={data.url}
                download={true}
                className="rounded-lg bg-sat_primary p-2"
              >
                <img src={download_icon} alt="download_icon" />
              </a>:null}
            </NavLink>:null
            }
          </div>
          <button
              onClick={() => setIsOpen(!open)}
              className={twJoin(
                (data?.readStatus == true || isRead==true)
                  ? "bg-[#CACACA] pointer-events-none"
                  : "bg-sat_primary",
                " text-white text-body-sm font-bold font-secondary px-3 py-2 rounded-lg w-full mt-5 fixed left-1/2 -translate-x-1/2 bottom-24 z-40 max-w-[87%] notice-board-acknowledge"
              )}
            >
              {t('acknowledge')}
            </button>
        </div>
      </div>
      <div
        className={twJoin(
          open ? "" : "hidden",
          "fixed left-0 top-0 w-full h-full bg-black bg-opacity-75 z-10"
        )}
      >
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg max-w-[290px] w-full">
          <div className="p-5">
            <p className="text-body-md font-normal text-sat_darkblackv2 text-center leading-[150%]">
              {t('ack_desc')}
            </p>
            <div className="mt-3 grid grid-cols-2 gap-3">
              <button
                onClick={() => {
                  setIsOpen(false);
                  readNoticeBoardDetail(data?.id);
                }}
                className="text-body-md px-3 py-2 bg-sat_primary font-bold text-white rounded-lg"
              >
                {t('confirm')}
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="text-body-md px-3 py-2 bg-transparent text-sat_cancel font-bold font-secondary"
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(NoticePopupV2);
