import { NavLink } from "react-router-dom";
import { BackIcon } from "../common/Icons";
import noti_icon from "../UserProfile/img/noti_icon.svg";
import { twJoin } from "tailwind-merge";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useRef, useState } from "react";
import CalendarEventDetail from "./CalendarEventDetail";
import { renderDateFormat } from "../DashboardCalendarView/DashboardAddEventPopup";
const CalendarView = (props: any) => {
  const curLanguage = localStorage.getItem("language")
  const [selectedDate, setSelectedData] = useState<string>("");
  const calendarRef = useRef<any>(null!);
  const render_event_color = () => {
    let evtList = props.eventData ? [...props.eventData] : [];
    [...evtList]?.map((eventInfo: any) => {
      const currentData = props.eventData?.filter(
        (x: any) => x.date == eventInfo.date
      );
      document
        .querySelectorAll(
          'td.fc-daygrid-day[data-date="' +
            eventInfo.date +
            '"] .fc-daygrid-day-events'
        )
        .forEach(function (event_div: any) {
          const date = event_div
            .closest("td.fc-daygrid-day")
            .getAttribute("data-date");
          if (eventInfo.date == date) {
            // console.log("eventInfo.event.extendedProps.eventType.color ",currentData,eventInfo.event.extendedProps.eventType.color,date)
            const color = eventInfo.eventType.color;
            const event_count = currentData.length;
            if (event_count > 0) {
              const width = 100 / event_count;
              setTimeout(function () {
                if (currentData.length == 1) {
                  currentData.map((data: any, key: any = 0) => {
                    const evt = event_div.querySelector(
                      ".fc-daygrid-event-harness:nth-child(1)"
                    );
                    if (evt) {
                      evt.style.width = "7px";
                      evt.style.backgroundColor = data.eventType.color;
                      evt.style.borderRadius = "20px";
                    }
                  });
                  return;
                }
                currentData.map((data: any, key: any = 0) => {
                  const evt = event_div.querySelector(
                    ".fc-daygrid-event-harness:nth-child(" + (key + 1) + ")"
                  );
                  if (evt) {
                    evt.style.width = width + "%";
                    evt.style.backgroundColor = data.eventType.color;
                    if (evt?.nextElementSibling) {
                      if (
                        evt?.nextElementSibling.classList.contains(
                          "fc-daygrid-event-harness"
                        )
                      ) {
                        evt.nextElementSibling.style.width = width + "%";
                        evt.nextElementSibling.style.backgroundColor =
                          data.eventType.color;
                      }
                    }
                  }
                });
              }, -1000);
            }
          }
        });
    });
  };

  render_event_color();
  return (
    <>
      <FullCalendar
        locale={curLanguage?curLanguage:'en'}
        ref={calendarRef}
        customButtons={{
          prevCustomButton: {
            icon: "chevron-left",
            click: function () {
              props.changeToPrevMonth(calendarRef);
            },
          },
          nextCustomButton: {
            icon: "chevron-right",
            click: function () {
              props.changeToNextMonth(calendarRef);
            },
          },
        }}
        headerToolbar={{
          left: "title",
          center: "prevCustomButton,nextCustomButton",
          right: "",
        }}
        dayHeaderFormat={{
          weekday: "narrow",
        }}
        events={props.eventData?.map((event: any) => {
          return {
            ...event,
            startDate: event.start,
            eventTitle: event.title,
          };
        })}
        eventOrder="classNames"
        // events={eventData}
        eventContent={props.renderEventContent}
        // eventColor="#378006"
        height="auto"
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        fixedWeekCount={false}
        dateClick={(info: any) => {
          //setSelectedData(info.dateStr);
        }}
      />
      <CalendarEventDetail
        eventData={props.eventData}
        selectedDate={selectedDate}
      />
    </>
  );
};
export default CalendarView;
