import { twJoin } from "tailwind-merge";
import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";
import { FilterDescIcon, FilterIcon } from "./Icons";
import { FC, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
export interface IDateFilter {
  filterData: any;
  filter: string;
  setFilter: any;
  filterYear: string;
  filterMonth?: string;
  setFilterYear: React.Dispatch<React.SetStateAction<string>>;
  setFilterMonth?: React.Dispatch<React.SetStateAction<string>>;
  years: any;
  filterByDate?: any;
  showMonth?: boolean;
  t: any;
}
export const localizedMonthNames = (
  locale = "default",
  dateStyle = "short"
) => {
  const monthNames = [];
  const currentDate = new Date();
  while (currentDate.getMonth() !== 0) {
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  for (let month = 0; month < 12; month++) {
    monthNames.push(currentDate.toLocaleDateString(locale, { month: "long" }));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return monthNames;
};
const monthNames_en = [...localizedMonthNames()];
const monthNames_tc = [...localizedMonthNames("zh")];
const monthNames_sc = [...localizedMonthNames("zh-cn")];
const DateFilter: FC<IDateFilter> = ({
  filterData,
  filter,
  setFilter,
  filterYear,
  setFilterYear,
  filterMonth,
  setFilterMonth,
  years,
  filterByDate,
  showMonth,
  t,
}) => {
  const [isDesc, setIsDesc] = useState<boolean>(false);
  const language = window.localStorage.getItem("language")
    ? window.localStorage.getItem("language")
    : "en";
  const [months, setMonths] = useState<string[]>(monthNames_en);
  useEffect(() => {
    setMonths(
      language == "en"
        ? monthNames_en
        : language == "zh"
        ? monthNames_tc
        : monthNames_sc
    );
  }, []);
  return (
    <div className="flex justify-between items-center mt-5">
      <div className="flex flex-wrap items-center">
        <div className={twJoin("flex items-center mr-[10px] w-auto my-1")}>
          <CustomDropdownListForFilter
            t={t}
            placeholder={"filter"}
            state={filter}
            setState={setFilter}
            dropdownItems={
              filterData
                ? [
                    ...filterData.map((st: any) => ({
                      value: st.id,
                      label: st.name,
                    })),
                  ]
                : []
            }
            textAlign="text-left"
            classNameCus="max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
          />
        </div>
        <div className={twJoin("flex items-center mr-[10px] w-auto my-1")}>
          <CustomDropdownListForFilter
            placeholder={"year"}
            t={t}
            state={filterYear}
            setState={setFilterYear}
            dropdownItems={
              years
                ? [
                    ...years.map((st: any) => ({
                      value: st.toString(),
                      label: st.toString(),
                    })),
                  ]
                : []
            }
            textAlign="text-left"
            classNameCus="max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
          />
        </div>
        <div
          className={twJoin(
            showMonth == true ? "flex" : "hidden",
            " items-center mr-[10px] w-auto my-1"
          )}
        >
          <CustomDropdownListForFilter
            t={t}
            placeholder={"month"}
            state={filterMonth}
            setState={setFilterMonth ? setFilterMonth : () => 1}
            dropdownItems={
              years
                ? [
                    ...months.map((st: any, key) => ({
                      value: key + 1,
                      label: st.toString(),
                    })),
                  ]
                : []
            }
            textAlign="text-left"
            classNameCus="max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
          />
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            setIsDesc(!isDesc);
            filterByDate(isDesc == true ? "desc" : "asc");
          }}
        >
          {isDesc ? <FilterDescIcon /> : <FilterIcon />}
        </button>
      </div>
    </div>
  );
};
export default DateFilter;
