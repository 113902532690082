import { withNamespaces } from "react-i18next";
import { NavLink } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import NotificationDataList from "./NotificationDataList";
import { useContext, useEffect, useState } from "react";
import {
  useGetNoticationsDataQuery,
  useReadNotificationDetailMutation,
} from "../../app/services/notifications";
import { NotificationContext } from "../UserProfile/UserProfile";
import { CircularProgress } from "@mui/material";
import { getLanguageCodeForbackend } from "../../app/services/api";
const noti_routes = [
  {
    label: "all",
    link: "/profile/notifications",
  },
  {
    label: "unread",
    link: "/profile/annoucements/unread",
  },
];

const NotificationList = (props: any) => {
  const { t } = props;
  const [language,setLanguage]=useState<string>('en/');
  const [activeTab, setActiveTab] = useState<string>("all");
  const notificationsContext: any = useContext(NotificationContext);
  const [notificationList, setNotificationList] = useState<any>([]);
  const { data: notificationsData, isLoading } = useGetNoticationsDataQuery(language);
  const [readNotification] = useReadNotificationDetailMutation();
  useEffect(() => {
    if (notificationsData) {
      notificationsContext.changeNotificationData(notificationsData);
      setNotificationList(notificationsData);
    }
  }, [notificationsData]);
  useEffect(()=>{
    const lang=getLanguageCodeForbackend();
    setLanguage(lang?lang:'en/');
  },[])
  useEffect(() => {
    const noti:any=notificationsData;
    if (activeTab != "all") {
      const unreads:any=[];
      const ddd=noti?.announcementList?.filter((x:any)=>{
        if(x.data?.filter((d:any)=>d.is_read==false).length>0){
          unreads.push({
            date:x.date,
            data:x.data?.filter((d:any)=>d.is_read==false)
          })
        }
        
        return x.data?.filter((d:any)=>d.is_read==false)
      });
      setNotificationList({
        announcementList: [
          ...unreads
        ],
        news: [
          ...noti?.news?.filter((x: any) => x.is_read == false),
        ],
        yesterdayList: [
          ...noti?.yesterdayList?.filter(
            (x: any) => x.is_read == false
          ),
        ],
      });
    } else {
      setNotificationList(notificationsData);
    }
  }, [activeTab]);
  const readNotificationData = (id: number) => {
    readNotification(id).then((res: any) => {
      if (res.status == true) {
        setNotificationList([
          ...notificationList?.map((noti: any) => {
            if (noti.id == id) {
              return { ...noti, is_read: true };
            }
            return noti;
          }),
        ]);
      }
    });
  };
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <NotificationContext.Provider value={notificationList}>
          <div className="mt-6 mb-20">
            <div className="grid grid-cols-2 gap-5 px-5">
              {noti_routes?.map((rout, key) => {
                return (
                  <button
                    onClick={() => setActiveTab(rout.label)}
                    key={key}
                    id={rout.label}
                    className={twJoin(
                      activeTab == rout.label
                        ? "border-b-2 border-b-sat_primary font-bold text-sat_darkblackv2"
                        : "border-b-2 border-b-transparent opacity-50 font-normal text-sat_darkblackv2",
                      "text-body-md w-full p-3"
                    )}
                  >
                    {t(rout.label)}
                  </button>
                );
              })}
            </div>
            <div className="mt-6">
              <NotificationDataList
                notifications={notificationList}
                readNotificationData={readNotificationData}
              />
            </div>
          </div>
        </NotificationContext.Provider>
      )}
    </>
  );
};
export default withNamespaces((props) => props.namespaces)(NotificationList);
