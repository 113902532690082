
import { useGetEventRemainderQuery } from "../../app/services/event-remainder";
import { useEffect, useMemo, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import {
  renderDateFormat,
  renderDateFormatDMY,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import CalendarEventDetail from "./CalendarEventDetail";
import CalendarView from "./CalendarView";
var date = new Date();
const CalendarEvent = (props: any) => {
  const [selectedDate, setSelectedData] = useState<string>("");
  const [month, setMonth] = useState<number>(date.getMonth() + 1);
  const [year, setYear] = useState<number>(date.getFullYear());
  
  const { data: events, isLoading } = useGetEventRemainderQuery({
    month: month,
    year: year,
  });
  const [eventsDataList, setEventsDataList] = useState([]);
  const [eventData, setEventData] = useState<any>();
  useEffect(() => {
    const eventCountData: any = [];
    if (events) {
      const eventsListData: any = events?.data;
      const groups = eventsListData?.reduce((groups: any, game: any) => {
        const date = game.date;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          events: groups[date],
        };
      });

      const refineEvents = [...eventsListData]?.map(
        (item: any, key: number) => {
          const eventLstArr = groupArrays.find((x) => {
            if (x.date == item.date) {
              return x.events;
            }
          });
          eventCountData.push(eventLstArr);

          const lastEventData = eventLstArr?.events.find(
            (x: any) => x.uniqueID == item.uniqueID && x.date == item.date
          );
          const currentData = eventsListData?.filter(
            (x: any) => x.date == item.date
          );
          return {
            classNames: currentData.length > 1 ? "" : "only-one-event-data",
            uniqueID: item.uniqueID,
            title: item.title,
            description: item.descripton,
            date: item.date,
            start: item.date,
            end: item.date,
            color: item.eventType.color,
            eventType: item.eventType,
          };
        }
      );
      var result = eventCountData.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.date === o.date)) {
          unique.push(o);
        }
        return unique;
      }, []);
      const dd: any = [];
      [...refineEvents]?.map((item: any) => {
        if (
          [...dd].find(
            (x) => x.date == item.date && x.classNames == "more-events-list"
          )
        ) {
          dd.push({
            classNames: "",
            uniqueID: item.uniqueID,
            title: item.title,
            description: item.descripton,
            date: item.date,
            start: item.date,
            end: item.date,
            color: item.eventType.color,
            eventType: item.eventType,
          });
        } else {
          dd.push({
            classNames: item.classNames ? item.classNames : "more-events-list",
            uniqueID: item.uniqueID,
            title: item.title,
            description: item.descripton,
            date: item.date,
            start: item.date,
            end: item.date,
            color: item.eventType.color,
            eventType: item.eventType,
          });
        }
      });
      const sorting = [...dd].sort(function (a: any, b: any) {
        if (a.classNames === "" || a.classNames === null) return -1;
        if (b.classNames === "" || b.classNames === null) return 1;
        if (a.classNames === b.classNames) return 0;
        return a > b ? -1 : 1;
      });
      setEventData([...sorting]);
    }
  }, [events]);

  const changeToPrevMonth = (calendarRef:any) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setMonth(month);
    setYear(year);
    calendarApi.refetchEvents();
    //getEventsDataByDate(month, year);
  };
  const changeToToday = (calendarRef:any) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setMonth(month);
    setYear(year);
    calendarApi.refetchEvents();
  };
  const changeToNextMonth = (calendarRef:any) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setMonth(month);
    setYear(year);
    calendarApi.refetchEvents();
  };
  const ref = useRef(null);
  const renderEventContent = (eventInfo: any) => {
    const currentData = eventData?.filter(
      (x: any) => x.date == eventInfo.event.extendedProps.startDate
    );
    const lastEvent = currentData[currentData.length - 1];

    return (
      <div
        className={twJoin("event_id_" + eventInfo.event.extendedProps.uniqueID)}
      >
        <li
          className={twJoin(
            currentData.length == 1 ? "only-one-event" : "",
            "w-[6px] h-[6px]"
          )}
        ></li>
      </div>
    );
  };
  useEffect(() => {
    document
      .querySelectorAll(".fc-daygrid-day-events .fc-daygrid-event-harness")
      .forEach(function (item) {
        const eventDiv = item.querySelectorAll(".fc-daygrid-event-harness");
        eventDiv.forEach(function (itm, key) {
          if (eventDiv.length > 1) {
            itm.classList.add("event-count-more");
          } else if (key == eventDiv.length - 1) {
            itm.classList.add("event-count-last-child");
          } else {
            itm.classList.add("event-count-one");
          }
        });
      });
  }, [props]);
  
  
  return (
    <div className="profile_calendar px-5">
      <CalendarView eventData={eventData} changeToPrevMonth={changeToPrevMonth} changeToNextMonth={changeToNextMonth} renderEventContent={renderEventContent} setSelectedData={setSelectedData}/>
      
    </div>
  );
};
export default CalendarEvent;
