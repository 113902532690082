import React, { useEffect } from "react";
import done_bg from "../../forgetpassword/img/done-bg.svg";
import { withNamespaces } from "react-i18next";
import { change_axios_language } from "../../app/services/api";

const PasswordResetSuccessComponent = (props:any) => {
  const {logoData,t}=props;  
  useEffect(()=>{
    change_axios_language();
  },[]);
  return (
    <div className="max-w-full">
      <div className="w-full mx-auto px-5">
        <div className="password-successful-container">
          <div className="pt-[124px] pb-10">
            <img
              src={done_bg}
              alt="done_bg"
              className="max-w-full mx-auto mt-10"
            />
            <div className="mt-[75px]">
              <p className="heading1 font-secondary font-normal leading-[135%] text-sat_darkblackv2 text-center">
                {t("your_set")}
              </p>
              <p className="mt-3 text-body-3 font-secondary font-normal text-sat_darkblackv2 text-center">
              {t("password_successful")}
              </p>
              <a
                href="/login"
                className="block leading-[normal] mt-5 rounded-lg py-3 px-3 text-white text-center text-body-md font-bold font-secondary w-full bg-sat_primary"
              >
                {t("login_now")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(PasswordResetSuccessComponent);
