import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useGetCMSDataQuery } from "../../app/services/cms";

const PrivacyPolicy = () => {
  const [privacy, setPrivacy] = useState<any>([]);
  const { data: cmsData, isLoading } = useGetCMSDataQuery("policy_privacy");
  useEffect(() => {
    if (cmsData) {
      setPrivacy(cmsData?.cms);
    }
  }, [cmsData]);
  return (
    <div>
      {isLoading == true ? (
        <CircularProgress />
      ) : (
        <div className="text-left privacy-policy px-5">
          <div className="pb-[70px]">
          <p dangerouslySetInnerHTML={{__html:privacy.description}}></p>
          </div>
          </div>
      )}
    </div>
  );
};
export default PrivacyPolicy;
